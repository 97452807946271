import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body py-4" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "symbol symbol-50px me-4" }
const _hoisted_4 = { class: "symbol-label w-50px rounded bg-light" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "fw-bold d-block fs-7 text-gray-400 mt-1" }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = { class: "svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderTitle = _resolveComponent("HeaderTitle")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProgerssBar = _resolveComponent("ProgerssBar")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card px-4"])
  }, [
    _createVNode(_component_HeaderTitle, {
      title: _ctx.$t('platformList.title'),
      subtitle: _ctx.$t('platformList.mutedTitle')
    }, null, 8, ["title", "subtitle"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "mb-4 d-flex justify-content-between align-items-center"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                _createElementVNode("img", {
                  src: item.image,
                  class: "h-75",
                  alt: ""
                }, null, 8, _hoisted_5)
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                to: {
                  name: 'platform-overview',
                  params: { id: item.id },
                },
                class: "text-dark fw-bold text-hover-primary fs-6"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.info.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]),
              _createElementVNode("span", _hoisted_6, _toDisplayString(item.info.description), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ProgerssBar, {
              percentage: item.progress.value,
              color: item.progress.color,
              title: `${item.progress.value}%`,
              status: "En cours"
            }, null, 8, ["percentage", "color", "title"]),
            _createVNode(_component_router_link, {
              to: "#",
              class: "btn btn-sm btn-light ms-12 w-25px d-flex justify-content-center"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_inline_svg, { src: "svg/arrows/arrow-right.svg" })
                ])
              ]),
              _: 1
            })
          ])
        ]))
      }), 128))
    ])
  ], 2))
}