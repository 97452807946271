
import ProgerssBar from "@/components/shared/progerssBar.vue";
import HeaderTitle from "@/components/shared/HeaderTitle.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { ProgerssBar, HeaderTitle },
  props: {
    widgetClasses: String,
  },
  setup() {
    const items = [
      {
        image: "media/technology-logos/Angular.png",
        info: {
          title: "Top Authors",
          description: "Successful Fellas",
        },
        progress: {
          value: 70,
          color: "#EF6327",
        },
        id: 1,
      },

      {
        image: "media/technology-logos/Angular.png",
        info: {
          title: "Popular Authors",
          description: "Most Successful",
        },
        progress: {
          value: 50,
          color: "#0BB783",
        },
        id: 2,
      },

      {
        image: "media/technology-logos/Angular.png",
        info: {
          title: "New Users",
          description: "Awesome Users",
        },
        progress: {
          value: 80,
          color: "#8950FC",
        },
        id: 1,
      },

      {
        image: "media/technology-logos/Angular.png",
        info: {
          title: "Active Customers",
          description: "Best Customers",
        },
        progress: {
          value: 90,
          color: "#F64E60",
        },
        id: 1,
      },

      {
        image: "media/technology-logos/Angular.png",
        info: {
          title: "Bestseller Theme",
          description: "Amazing Templates",
        },
        progress: {
          value: 70,
          color: "#3699FF",
        },
        id: 1,
      },
    ];
    return {
      items,
    };
  },
});
