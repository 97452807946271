import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52bb1c45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-info-card__wrapper" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "fs-1" }
const _hoisted_4 = { class: "fs-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.count), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ]),
    _createVNode(_component_inline_svg, {
      src: _ctx.src,
      class: "ms-auto",
      width: "30",
      height: "30"
    }, null, 8, ["src"])
  ]))
}