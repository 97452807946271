import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f71419ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-card" }
const _hoisted_2 = { class: "mb-0 fs-5" }
const _hoisted_3 = { class: "ms-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createElementVNode("span", _hoisted_3, [
      _createVNode(_component_inline_svg, {
        src: _ctx.icon,
        width: "30",
        height: "30"
      }, null, 8, ["src"])
    ])
  ]))
}