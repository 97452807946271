
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    shape: {
      type: String,
      default: "circle",
    },
    size: {
      type: Number,
      default: 40,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  },
});
