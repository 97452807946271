
import { defineComponent, ref } from "vue";
import useTranslate from "@/core/composable/useTranslate";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ticketsTable",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const { translate } = useTranslate();
    const route = useRoute();

    const ticketList = [
      {
        ticketID: "DFP-159",
        description: "Create design Ticket",
        date: "Jan 9, 2021",
        user: {
          name: "alex",
        },
        status: {
          label: translate("ticketsTable.completed"),
          color: "success",
        },
      },
      {
        ticketID: "DA-24",
        date: "Sep 21, 2020",
        description: "User Module Testing",
        user: {
          name: "marco",
        },
        status: {
          label: translate("ticketsTable.inProgress"),
          color: "info",
        },
      },
      {
        ticketID: "DA-23",
        date: "Feb 27, 2021",
        description: "Sales report page",
        user: {
          name: "Shaun",
        },
        status: {
          label: translate("ticketsTable.inProgress"),
          color: "success",
        },
      },
      {
        ticketID: "DA-23",
        date: "May 2, 2022",
        description: "Sales report page",
        user: {
          name: "Linda",
        },
        status: {
          label: translate("ticketsTable.inProgress"),
          color: "info",
        },
      },
      {
        ticketID: "DA-23",
        date: "May 2, 2022",
        description: "Sales report page",
        user: {
          name: "Paul",
        },
        status: {
          label: translate("ticketsTable.toDo"),
          color: "secondary",
        },
      },
      {
        ticketID: "DA-23",
        date: "May 2, 2022",
        description: "Sales report page",
        user: {
          name: "Paul",
        },
        status: {
          label: translate("ticketsTable.toDo"),
          color: "secondary",
        },
      },
      {
        ticketID: "DA-23",
        date: "May 2, 2022",
        description: "Sales report page",
        user: {
          name: "Paul",
        },
        status: {
          label: translate("ticketsTable.toDo"),
          color: "secondary",
        },
      },
    ];
    const isAdmin = ref(route.path.split("dashboard/")[1] === "admin");
    return {
      ticketList,
      isAdmin,
    };
  },
});
