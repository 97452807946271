
import { computed, defineComponent } from "vue";
import UserInfo from "@/components/shared/UserInfo.vue";
import HeaderTitle from "@/components/shared/HeaderTitle.vue";
import useTranslate from "@/core/composable/useTranslate";
export default defineComponent({
  name: "list-member",
  components: {
    UserInfo,
    HeaderTitle,
  },
  props: {
    widgetClasses: String,
    listMembers: Array,
  },
  setup(props) {
    const { translate } = useTranslate();
    const subtitle = computed(() => {
      return `${translate("listMember.mutedTitle1")} ${
        props.listMembers?.length
      } 
       ${translate("listMember.mutedTitle2")}`;
    });
    return { subtitle };
  },
});
