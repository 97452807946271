import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-691f7b7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashbord-container" }
const _hoisted_2 = { class: "info-card-container" }
const _hoisted_3 = { class: "mb-4 d-flex justify-content-between container-card" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardInfoCard = _resolveComponent("DashboardInfoCard")!
  const _component_DashbordCreateCard = _resolveComponent("DashbordCreateCard")!
  const _component_TicketsTable = _resolveComponent("TicketsTable")!
  const _component_ListMember = _resolveComponent("ListMember")!
  const _component_PlatformList = _resolveComponent("PlatformList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat, index) => {
        return (_openBlock(), _createBlock(_component_DashboardInfoCard, {
          key: index,
          src: stat.icon,
          title: stat.content,
          count: stat.number
        }, null, 8, ["src", "title", "count"]))
      }), 128))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DashbordCreateCard, {
          text: "Créer Platformes",
          icon: "svg/dashbord/equalizer.svg",
          onClick: _cache[0] || (_cache[0] = () => _ctx.setModal('KTCreatePlatformModal'))
        }),
        _createVNode(_component_DashbordCreateCard, {
          text: "Créer Ticket",
          icon: "svg/dashbord/vector.svg",
          onClick: _cache[1] || (_cache[1] = () => _ctx.setModal('KTCreateNewTicket'))
        }),
        _createVNode(_component_DashbordCreateCard, {
          text: "Créer Utilisateurs",
          icon: "svg/dashbord/add-user.svg",
          onClick: _cache[2] || (_cache[2] = () => _ctx.setModal('KTAddUser'))
        }),
        _createVNode(_component_DashbordCreateCard, {
          text: "Créer Rôles",
          icon: "svg/dashbord/compiling.svg",
          onClick: _cache[3] || (_cache[3] = () => _ctx.setModal('AddRole'))
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_TicketsTable)
      ])
    ]),
    (_ctx.allUsers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_ListMember, { "list-members": _ctx.allUsers }, null, 8, ["list-members"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_PlatformList)
    ])
  ]))
}