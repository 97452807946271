
import { computed, defineComponent, onMounted, ref } from "vue";
import TicketsTable from "@/components/widgets/tables/TicketsTable.vue";
import ListMember from "@/components/shared/ListMember.vue";
import PlatformList from "@/views/main/dashboard/dashboardAdmin/parts/PlatformList.vue";
import useTranslate from "@/core/composable/useTranslate";
import { setCurrentPageTitle } from "@/core/helpers/config";
import { setModal } from "@/core/helpers/config";
import DashboardInfoCard from "./parts/DashboardInfoCard.vue";
import DashbordCreateCard from "./parts/DashbordCreateCard.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  components: {
    TicketsTable,
    ListMember,
    PlatformList,
    DashboardInfoCard,
    DashbordCreateCard,
  },
  setup() {
    const { translate } = useTranslate();
    const store = useStore();
    store.dispatch(Actions.FETCH_INFO_ALL_USERS);

    const allUsers = computed(() => {
      return store.getters.usersInfo;
    });
    const stats = ref([
      {
        icon: "svg/dashbord/platform.svg",
        content: translate("dashboardAdmin.statsContent[0]"),
        number: 5,
      },
      {
        icon: "svg/dashbord/ticket.svg",
        content: translate("dashboardAdmin.statsContent[1]"),
        number: 20,
      },
      {
        icon: "svg/dashbord/bug.svg",
        content: translate("dashboardAdmin.statsContent[2]"),
        number: 3,
      },
      {
        icon: "svg/dashbord/group.svg",
        content: translate("dashboardAdmin.statsContent[3]"),
        number: 13,
      },
    ]);
    onMounted(() => {
      setCurrentPageTitle("Accueil");
    });
    return {
      stats,
      setModal,
      allUsers,
    };
  },
});
