
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: { type: String, default: "#0000ff" },
    percentage: { type: Number, required: true },
    textInside: { type: Boolean, default: true },
    strokeWidth: { type: Number, default: 5 },
    title: { type: String, required: false },
    status: { type: String, required: false },
    label: { type: String, default: "" },
  },
  components: {},
  setup() {
    return {};
  },
});
