
import { defineComponent } from "vue";
import Avatar from "@/components/shared/Avatar.vue";
export default defineComponent({
  components: { Avatar },
  props: {
    firstName: String,
    lastName: String,
    position: String,
  },
});
