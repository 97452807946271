
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    src: {
      type: String,
      default: "svg/icons/image-file-thummbnail.svg",
    },

    count: { type: Number },
    title: { type: String },
  },
  setup() {
    return {};
  },
});
