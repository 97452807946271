import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center gap-4" }
const _hoisted_2 = { class: "text-muted fw-bold fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Avatar, {
      firstName: _ctx.firstName,
      lastName: _ctx.lastName,
      shape: "square"
    }, null, 8, ["firstName", "lastName"]),
    _createElementVNode("div", null, [
      _createVNode(_component_router_link, {
        to: "#",
        class: "text-dark text-hover-primary fs-6 d-block"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.firstName[0].toUpperCase() + _ctx.firstName.slice(1) + " " + _ctx.lastName), 1)
        ]),
        _: 1
      }),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.position), 1)
    ])
  ]))
}