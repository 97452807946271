import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f9bb9bb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column fv-row" }
const _hoisted_2 = { class: "progress-bar-title mb-2 d-flex" }
const _hoisted_3 = { class: "ms-auto" }
const _hoisted_4 = { class: "progress-bar-container" }
const _hoisted_5 = {
  key: 0,
  class: "progress-bar-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.status), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_progress, {
        "text-inside": _ctx.textInside || !_ctx.label,
        "stroke-width": _ctx.strokeWidth,
        percentage: _ctx.percentage,
        color: _ctx.color
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            (_ctx.label !== '')
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.label), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["text-inside", "stroke-width", "percentage", "color"])
    ])
  ]))
}